import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMoneyBillWave,
	faBolt,
	faBuilding,
	faMapLocationDot,
	faFileContract,
	faStopwatch,
	faHandshake,
	faPiggyBank,
	faRocket,
	faArrowsRotate,
	faHouse
} from '@fortawesome/free-solid-svg-icons';
import styles from './SellLand.module.css';
import axios from 'axios';
import { baseUrl } from '../config.js';

function SellLand({ english }) {
	// Add this useEffect at the beginning of your component
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [ form, setForm ] = useState({
		name: '',
		phone: '',
		email: '',
		message: ''
	});
	const [ button, setButton ] = useState(english ? 'Send' : 'Изпрати');
	const [ error, setError ] = useState('');

	const translations = {
		en: {
			nameLabel: 'Your Name*:',
			phoneLabel: 'Your Phone*:',
			emailLabel: 'Email:',
			messageLabel: 'Message:',
			send: 'Send',
			sending: 'Sending...',
			received: 'Received.',
			nameError: 'Please enter your name.',
			phoneError: 'Please enter your phone number.',
			messageGuidance:
				'Please share details about your land or plot: location, size, current status (e.g., zoning), and any price expectations. The more information you provide, the better we can assist you.'
		},
		bg: {
			nameLabel: 'Вашето име*:',
			phoneLabel: 'Вашият телефон*:',
			emailLabel: 'Email:',
			messageLabel: 'Събщение:',
			send: 'Изпрати',
			sending: 'Изпраща се...',
			received: 'Получено.',
			nameError: 'Ако обичате попълнете името си.',
			phoneError: 'Ако обичате попълнете телефонен номер.',
			messageGuidance:
				'Моля, споделете подробности за вашата земя или парцел: местоположение, размер, текущо състояние (напр. зониране) и евентуални ценови очаквания. Колкото повече информация предоставите, толкова по-добре можем да ви съдействаме.'
		}
	};

	const t = translations[english ? 'en' : 'bg'];

	function validateInput() {
		setButton(english ? 'Sending...' : 'Изпраща се');
		if (form.name.length < 2) {
			setError(t.nameError);
			return false;
		}
		if (form.phone.length < 2) {
			setError(t.phoneError);
			return false;
		}
		return true;
	}

	async function handleSubmit(e) {
		e.preventDefault();
		const valid = validateInput();
		if (!valid) return;

		try {
			const response = await axios.post(`${baseUrl}/homemessage`, form);

			if (response.status === 201) {
				setButton(t.received);
				setForm({
					name: '',
					phone: '',
					email: '',
					message: ''
				});
			}
		} catch (e) {
			console.log(e);
		}
	}

	function handleChange(e) {
		setForm((prev) => ({
			...prev,
			[e.target.name]: e.target.value
		}));
	}

	const features = [
		{
			icon: faMoneyBillWave,
			text: english ? 'Fair market value offers' : 'Оферти на справедлива пазарна стойност'
		},
		{ icon: faBolt, text: english ? 'Quick and hassle-free transactions' : 'Бързи и безпроблемни сделки' },
		{ icon: faBuilding, text: english ? 'Experienced real estate developers' : 'Опитни строителни предприемачи' },
		{ icon: faMapLocationDot, text: english ? 'Local market expertise' : 'Експертиза на местния пазар' },
		{
			icon: faFileContract,
			text: english
				? 'We handle all paperwork and legal processes'
				: 'Ние се грижим за цялата документация и правни процеси'
		}
	];

	const agencyComparison = [
		{ icon: faStopwatch, text: english ? 'Super quick response time' : 'Изключително бързо време за отговор' },
		{
			icon: faHandshake,
			text: english ? 'Direct communication with decision-makers' : 'Директна комуникация с вземащите решения'
		},
		{ icon: faPiggyBank, text: english ? 'No middleman fees' : 'Без посреднически такси и комисионни' },
		{ icon: faRocket, text: english ? 'Faster closing process' : 'По-бърз процес на приключване на сделката' },
		{
			icon: faArrowsRotate,
			text: english ? 'Potential for flexible deal structures' : 'Възможност за гъвкави структури на сделката'
		},
		{
			icon: faHouse,
			text: english
				? 'We buy land directly, no need to find a buyer'
				: 'Купуваме земята директно, без нужда от търсене на купувач'
		}
	];

	return (
		<div className={styles.sellLandContainer}>
			<Helmet>
				<title>{english ? 'Sell Your Land or Plot in Sofia or Bistritsa - Vkushty' : 'Продайте Вашата Земя или Парцел в София или Бистрица - Вкъщи'}</title>
				<meta name="description" content={english ? 'Sell your land or plot in Southern Sofia or Bistritsa to Vkushty. We offer fair market value for prime real estate development opportunities.' : 'Продайте вашата земя или парцел в Южна София или Бистрица на Вкъщи. Предлагаме справедлива пазарна стойност за първокласни възможности за развитие на недвижими имоти.'} />
				<link rel="canonical" href="https://vkushty.com/parceli-i-zemi-prodajba" />
			</Helmet>

			<h1>
				{english ? 'Sell Your Land or Plot in Sofia or Bistritsa' : 'Продайте Вашата Земя или Парцел в гр. София или с. Бистрица'}
			</h1>
			<div className={styles.introSection}>
				<p>
					{english ? (
						"Are you looking to sell your land or plot in Southern Sofia or Bistritsa, Bulgaria? You've come to the right place. At Vkushty, we specialize in purchasing prime real estate for development in these sought-after areas. Our team of experienced real estate professionals is dedicated to providing a seamless, efficient, and fair process for landowners looking to sell their property. Whether you have a residential plot, commercial land, or agricultural land with potential for rezoning, we're interested in hearing from you. Our deep understanding of the local market, combined with our commitment to fair dealings, makes us the ideal partner for your land or plot sale needs in Sofia and its surrounding areas."
					) : (
						'Търсите ли да продадете земята или парцела си в Южна София или Бистрица, България? Попаднали сте на правилното място. Във Вкъщи ние се специализираме в закупуването на първокласни имоти за развитие в тези търсени райони. Нашият екип от опитни професионалисти в областта на недвижимите имоти е посветен на осигуряването на безпроблемен, ефективен и справедлив процес за собствениците на земя или парцели, които искат да продадат своя имот. Независимо дали имате жилищен парцел, търговска земя или земеделска земя с потенциал за промяна на предназначението, ние се интересуваме да чуем от вас. Нашето дълбоко разбиране на местния пазар, съчетано с ангажимента ни за справедливи сделки, ни прави идеалния партньор за вашите нужди от продажба на земя или парцел в София и околностите й.'
					)}
				</p>
			</div>

			<h2>{english ? 'How It Works' : 'Как работи процесът'}</h2>
			<div className={styles.howItWorks}>
				<div className={styles.step}>
					<div className={styles.stepNumber}>1</div>
					<h3>{english ? 'Contact Us' : 'Свържете се с нас'}</h3>
					<p>
						{english ? (
							'Reach out with details about your land or plot'
						) : (
							'Свържете се с подробности за вашата земя или парцел'
						)}
					</p>
				</div>
				<div className={styles.step}>
					<div className={styles.stepNumber}>2</div>
					<h3>{english ? 'Assessment & Offer' : 'Оценка и Оферта'}</h3>
					<p>
						{english ? (
							'We evaluate your property and provide a fair market value offer'
						) : (
							'Оценяваме вашия имот и предоставяме оферта на справедлива пазарна стойност'
						)}
					</p>
				</div>
				<div className={styles.step}>
					<div className={styles.stepNumber}>3</div>
					<h3>{english ? 'Closing' : 'Приключване'}</h3>
					<p>
						{english ? (
							'We handle paperwork and complete the sale'
						) : (
							'Ние се грижим за документацията и завършваме продажбата'
						)}
					</p>
				</div>
			</div>

			<h2>{english ? 'Why Sell Your Land or Plot to Us?' : 'Защо да продадете земята или парцела си на нас?'}</h2>
			<div className={styles.featureGrid}>
				{features.map((feature, index) => (
					<div key={index} className={styles.featureItem}>
						<div className={styles.featureIcon}>
							<FontAwesomeIcon icon={feature.icon} />
						</div>
						<p>{feature.text}</p>
					</div>
				))}
			</div>

			<h2>{english ? 'Why Us Instead of an Agency?' : 'Защо ние, а не агенция?'}</h2>
			<div className={styles.featureGrid}>
				{agencyComparison.map((feature, index) => (
					<div key={index} className={styles.featureItem}>
						<div className={styles.featureIcon}>
							<FontAwesomeIcon icon={feature.icon} />
						</div>
						<p>{feature.text}</p>
					</div>
				))}
			</div>

			<h2>{english ? 'Areas of Interest' : 'Райони от интерес'}</h2>
			<p className={styles.sectionIntro}>
				{english ? (
					'We are actively looking for land in the following areas:'
				) : (
					'Активно търсим земя в следните райони:'
				)}
			</p>
			<ul className={styles.featureGrid}>
				<div className={styles.featureItem}>
					{english ? 'Southern Sofia, Bulgaria' : 'Южна София, България'}
				</div>
				<div className={styles.featureItem}>
					{english ? 'Bistritsa, Sofia, Bulgaria' : 'Бистрица, София, България'}{' '}
				</div>
			</ul>

			<h2>{english ? "Types of Land We're Interested In" : 'Видове земя, от които се интересуваме'}</h2>
            <ul className={styles.featureGrid}>
    <div className={styles.featureItem}>{english ? 'Residential development plots' : 'Парцели за жилищно строителство'}</div>
    <div className={styles.featureItem}>{english ? 'Mixed-use development land' : 'Земя за смесено ползване'}</div>
    <div className={styles.featureItem}>
        {english
            ? 'Agricultural land with potential for rezoning'
            : 'Земеделска земя с потенциал за промяна на предназнаението'
        }
    </div>
</ul>

			<h2>{english ? 'Contact Us Today' : 'Свържете се с нас днес'}</h2>
			<p>
				{english ? (
					"Ready to sell your land or plot, or have questions? Fill out the form below, and we'll get back to you as soon as possible!"
				) : (
					'Готови ли сте да продадете земята или парцела си или имате въпроси? Попълнете формуляра по-долу и ще се свържем с вас възможно най-скоро!'
				)}
			</p>

			<div className={styles.contactFormWrapper}>
				<form className={styles.contactForm} onSubmit={handleSubmit}>
					<label>
						{t.nameLabel}
						<input type="text" maxLength={200} name="name" value={form.name} onChange={handleChange} />
					</label>
					<label>
						{t.phoneLabel}
						<input type="tel" maxLength={200} name="phone" value={form.phone} onChange={handleChange} />
					</label>
					<label>
						{t.emailLabel}
						<input type="email" maxLength={200} name="email" value={form.email} onChange={handleChange} />
					</label>
					<label>
						{t.messageLabel}
						<p className={styles.messageGuidance}>{t.messageGuidance}</p>
						<textarea maxLength={500} name="message" value={form.message} onChange={handleChange} />
					</label>
					<button className={styles.subButton} type="submit">
						{button}
					</button>
				</form>
				{error && <p className={styles.errorMessage}>{error}</p>}
			</div>

			<div className={styles.contactInfo}>
				<h2>{english ? 'Contact Us' : 'Свържете се с нас'}</h2>
				<p>Email: home@vkushty.com</p>
				<p>{english ? 'Phone: +359 8 777 1444 9' : 'Телефон: +359 8 777 1444 9'}</p>
			</div>
		</div>
	);
}

export default SellLand;
